import { trim } from "./string.helper";
import { currencies, origins } from "../store/enums";

/**
 * Get base url
 * @returns {string}
 */
export const getBase = () => {
  let url = window.location;
  return url.protocol + "//" + url.host;
};

/**
 * Go to url
 */
export const goTo = (url) => {
  window.location.href = url;
};

/**
 * Get active url, excluding the last '/'
 * @returns {string}
 */
export const getActive = () => {
  return trim(window.location.toString(), "/");
};

/**
 * Concatenate url components
 * @param var_args
 * @returns {string}
 */
export const join = (var_args) => {
  let _args = Array.prototype.slice.call(var_args);
  let args = [];

  // remove slashes and backslashes from the beginning
  // and ending of each path piece, to avoid duplicates
  for (let k = 0; k < _args.length; k++) {
    let piece = _args[k];

    while (piece.charAt(0) === "/") piece = piece.substr(1);

    while (piece.charAt(0) === "\\") piece = piece.substr(1);

    while (piece.charAt(piece.length - 1) === "/")
      piece = piece.substr(0, piece.length - 1);

    while (piece.charAt(piece.length - 1) === "\\")
      piece = piece.substr(0, piece.length - 1);

    if (piece !== "") args.push(piece);
  }

  return args.join("/");
};

/**
 * Parse values from origin segment of checkout link
 */
export const getOriginValues = (originString) => {
  let originValues = {
    origin: origins.website,
    currency: currencies.pen,
    classroomStudentId: null,
    classroomCourseId: null,
  };

  // when originString is not defined, return default values

  if (!originString) {
    return originValues;
  }

  // When origin string has an incorrect format, return default value
  const correctFormat = (originString.length > 1 && originString.includes('-'));
  if (!correctFormat) {
    return originValues;
  }

  // set new values from url parameter

  let values = originString.split('-');

  // set origin

  if (values[0]) {
    originValues.origin = values[0];
  }

  // set currency

  if (originValues.origin === origins.website && values[1]) {
    switch (values[1]) {
      case currencies.pen:
      case currencies.mxn:
      case currencies.usd:
        originValues.currency = values[1];
        break;
      default:
        originValues.currency = currencies.pen;
    }
  }

  return originValues;
};
