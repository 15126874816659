import { companies } from "../../../../store/enums";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../../store/appInfoSlicer";
import { initializeTranslator } from "../../../../helpers/language.helper";
import "./BankAccountsInformation.scss";

/** Banks enum */
export const banks = Object.freeze({
  bancoNacion: "bancoNacion",
  bcp: "bcp",
  bcp2: "bcp2",
  interbank: "interbank",
  bbva: "bbva",
  scotiabank: "scotiabank",
  other: 'other',
});

/**
 * Get bank's account name according company
 */
const getAccountNumber = (companyName, bankName) => {
  if (!companyName) return;

  let accountNumbers;
  if (companyName === companies.enpp) {
    accountNumbers = {
      bancoNacion: {
        description: "Cuenta simple soles",
        number: "00-015-045086",
      },
      bcp: { description: "Cuenta simple soles", number: "193-2315405-0-28" },
      interbank: {
        description: "Cuenta simple soles",
        number: "0873001763029",
      },
      bbva: {
        description: "Cuenta simple soles",
        number: "0011-0147-0100083450",
      },
      scotiabank: { description: "Cuenta simple soles", number: "0008125350" },
    };
  }

  if (companyName === companies.ipappg) {
    accountNumbers = {
      bancoNacion: {
        description: "Cuenta de ahorros",
        number: "04-052-191772",
      },
      bcp: { description: "Cuenta corriente", number: "193-2260106-0-52" },
      interbank: { description: "Cuenta corriente", number: "087-300126963-6" },
      bbva: { description: "Cuenta corriente", number: "0011-0147-0100076128" },
      scotiabank: { description: "Cuenta corriente", number: "0004029593" },
    };
  }

  if (companyName === companies.pragmma) {
    accountNumbers = {
      bcp: { description: "Cuenta corriente Soles", number: "191-4207218-0-50" },
      bcp2: { description: "Cuenta corriente Dolares", number: "191-4207228-1-61" },
      interbank: { description: "Cuenta Negocios Soles", number: "2003006029440" },
      bbva: { description: "Cuenta corriente soles", number: "0011-0128-0200842002" },
      scotiabank: { description: "Cuenta corriente soles", number: "(000)3935856" },
      other: { description: "Para transferencias desde otros bancos", number: "CCI: 00219100420722816153 " },
    };
  }

  return accountNumbers[bankName];
};

/**
 * Get Whatsapp number according company
 */
const getWhatsappNumbers = (companyName, message) => {
  if (!companyName) return;

  if (companyName === companies.enpp) {
    return `<a href="https://wa.me/51946554339?text=${message}"
                   target="_blank"> (+51) 946 554 339 </a> 
                   (Carlos Alberto Mendoza Pinillos - Jefe de Ventas)`;
  }

  if (companyName === companies.ipappg)
    return `<a href="https://wa.me/51999051729?text=${message}"
                   class="me-3"
                   target="_blank"> +51 959194830 </a>
               <a href="https://wa.me/51949483153?text=${message}"
                  target="_blank"> +51 949483153 </a>
        `;

  if (companyName === companies.pragmma)
    return `<a href="https://wa.me/51908824570?text=${message}"
                   class="me-3"
                   target="_blank"> +51 908824570 </a>
        `;
};

/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const BankAccountsInfo = (props) => {
  // get language value from Redux state
  const language = useSelector(selectLanguage);

  // initialize translator with current language
  const t = initializeTranslator(language);

  // return component

  return (
    <div className="bank-accounts-info-component">
      {/* Bank accounts */}

      <div className="bank-accounts row p-0">
        <div className="col-12 col-xxl-6 bank-account-wrapper pt-3 pb-3">
          <div className="row p-0">
            <div className="col-5 p-1">
              <img src="imgs/logos/banks/bcp.png" className="ms-2" alt="" />
            </div>
            <div className="col-7 ps-1">
              <p className="description">
                {getAccountNumber(props.companyName, banks.bcp).description}
              </p>
              <p className="number">
                {getAccountNumber(props.companyName, banks.bcp).number}
              </p>
            </div>
          </div>
        </div>

        { props.companyName === companies.pragmma
            ? <div className="col-12 col-xxl-6 bank-account-wrapper pt-3 pb-3">
              <div className="row p-0">
                <div className="col-5 p-1">
                  <img src="imgs/logos/banks/bcp.png" className="ms-2" alt="" />
                </div>
                <div className="col-7 ps-1">
                  <p className="description">
                    {getAccountNumber(props.companyName, banks.bcp2).description}
                  </p>
                  <p className="number">
                    {getAccountNumber(props.companyName, banks.bcp2).number}
                  </p>
                </div>
              </div>
            </div>
            : null
        }

        { props.companyName !== companies.pragmma
            ? <div className="col-12 col-xxl-6 bank-account-wrapper pt-3 pb-3">
              <div className="row p-0">
                <div className="col-5 p-1">
                  <img
                      src="imgs/logos/banks/banco-nacion.png"
                      className="ms-2"
                      alt=""
                  />
                </div>
                <div className="col-7 ps-1">
                  <p className="description">
                    {
                      getAccountNumber(props.companyName, banks.bancoNacion)
                          .description
                    }
                  </p>
                  <p className="number">
                    {getAccountNumber(props.companyName, banks.bancoNacion).number}
                  </p>
                </div>
              </div>
            </div>
            : null
        }
      </div>

      <div className="bank-accounts row p-0">
        <div className="col-12 col-xxl-6 bank-account-wrapper pt-3 pb-3">
          <div className="row p-0">
            <div className="col-5 p-1">
              <img
                src="imgs/logos/banks/interbank.png"
                className="ms-2"
                alt=""
              />
            </div>
            <div className="col-7 ps-1">
              <p className="description">
                {
                  getAccountNumber(props.companyName, banks.interbank)
                    .description
                }
              </p>
              <p className="number">
                {getAccountNumber(props.companyName, banks.interbank).number}
              </p>
            </div>
          </div>
        </div>

        <div className="col-12 col-xxl-6 bank-account-wrapper pt-3 pb-3">
          <div className="row p-0">
            <div className="col-5 p-1">
              <img src="imgs/logos/banks/bbva.png" className="ms-2" alt="" />
            </div>
            <div className="col-7 ps-1">
              <p className="description">
                {getAccountNumber(props.companyName, banks.bbva).description}
              </p>
              <p className="number">
                {getAccountNumber(props.companyName, banks.bbva).number}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bank-accounts row p-0 mb-5">
        <div className="col-12 col-xxl-6 bank-account-wrapper pt-3 pb-3">
          <div className="row p-0">
            <div className="col-5 p-1">
              <img
                src="imgs/logos/banks/scotiabank.png"
                className="ms-2"
                alt=""
              />
            </div>
            <div className="col-7 ps-1">
              <p className="description">
                {
                  getAccountNumber(props.companyName, banks.scotiabank)
                    .description
                }
              </p>
              <p className="number">
                {getAccountNumber(props.companyName, banks.scotiabank).number}
              </p>
            </div>
          </div>
        </div>


        { props.companyName === companies.pragmma
            ? <div className="col-12 col-xxl-6 bank-account-wrapper pt-3 pb-3">
              <div className="row p-0">
                <div className="col-12 ps-1">
                  <p className="description">
                    {
                      getAccountNumber(props.companyName, banks.other)
                          .description
                    }
                  </p>
                  <p className="number">
                    {getAccountNumber(props.companyName, banks.other).number}
                  </p>
                </div>
              </div>
            </div>
            : null
        }
      </div>


      {/* Message */}

      <div className="message">
        <div className="icon-wrapper">
          <div className="icon">
            <img src="imgs/icons/warning.svg" alt="" />
          </div>
        </div>

        {props.installmentNumber >= 2 ? (
          <div>
            <p>Enviar el comprobante de pago al siguiente correo:</p>
            <p>
              <a href="mailto:facturacion.backoffice@gmail.com">
                facturacion@backofficelima.com.pe
              </a>
            </p>
          </div>
        ) : (
          <div>
            <p>{t("whatsappInvoiceMessage")}</p>
            <p>
              <img src="imgs/logos/whatsapp.svg" alt="" className="me-3 icon" />
              <span
                dangerouslySetInnerHTML={{
                  __html: getWhatsappNumbers(
                    props.companyName,
                    t("whatsappMessage")
                  ),
                }}
              />
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BankAccountsInfo;
