import { useSelector } from "react-redux";
import { selectLanguage } from "../../../store/appInfoSlicer";
import { initializeTranslator } from "../../../helpers/language.helper";
import { useEffect, useState } from "react";
import { companies, paymentMethods } from "../../../store/enums";
import YapeInformation from "./YapeInformation/YapeInformation";
import PagoefectivoForm from "./PagoEfectivo/PagoefectivoForm/PagoefectivoForm";
import "./PaymentWithoutCard.scss";

/**
 * Component
 * @returns {JSX.Element}
 * @constructor
 */
const PaymentWithoutCard = (props) => {
  // get language value from Redux state
  const language = useSelector(selectLanguage);

  // initialize translator with current language
  const t = initializeTranslator(language);

  // event handler for changed event in payment method
  const onPaymentMethodChanged = (e) => {
    // update state
    setPaymentMethod(e.currentTarget.value);

    // update flag to force resize of parent wrapper
    setResizeIsPending(true);
  };

  // set state values
  // ---------------------------------------

  // select payment method
  let [paymentMethod, setPaymentMethod] = useState(paymentMethods.pagoefectivo);
  // flag: resize is pending
  let [resizeIsPending, setResizeIsPending] = useState(false);

  // lifecycle hooks
  // ---------------------------------------

  // hook to be executed when
  // component has been updated
  useEffect(() => {
    if (resizeIsPending) {
      // tell parent component to recalculate
      // payment information wrapper height, after
      // a delay to wait rendering completion
      setTimeout(() => {
        props.onSizeChange();
      }, 100);

      // update flag
      setResizeIsPending(false);
    }
  }, [resizeIsPending]);

  // return component

  return (
    <div className="payment-without-card-component">
      <div className="row">
        <div className="col-12 pb-5 pt-4">
          <div className="row pt-4">
            {/* Payment method selector */}

            {props.companyName === companies.enpp ? (
              <div className="col-4">
                <input
                  id="method-radio1"
                  type="radio"
                  name="payment-method"
                  value={paymentMethods.yape}
                  onChange={onPaymentMethodChanged}
                  checked={paymentMethod === paymentMethods.yape}
                />
                <img src="imgs/logos/yape.png" className="yape logo" alt="" />
              </div>
            ) : null}

            <div className="col-8">
              <input
                id="method-radio2"
                type="radio"
                name="payment-method"
                value={paymentMethods.pagoefectivo}
                onChange={onPaymentMethodChanged}
                checked={paymentMethod === paymentMethods.pagoefectivo}
              />
              <img
                src="imgs/logos/pagoefectivo.svg"
                className="pagoefectivo logo"
                alt=""
              />
            </div>
          </div>
          <div className="col-12">
            {/* Yape payment information */}

            {paymentMethod === paymentMethods.yape &&
            props.companyName === companies.enpp ? (
              <YapeInformation />
            ) : null}

            {/* Pagoefectivo costumer form */}

            {paymentMethod === paymentMethods.pagoefectivo ? (
              <PagoefectivoForm
                courseName={props.courseName}
                companyName={props.companyName}
                courseCode={props.courseCode}
                withInstallments={props.withInstallments}
                installmentNumber={props.installmentNumber}
                certificates={props.certificates}
                showSpinner={props.showSpinner}
                hideSpinner={props.hideSpinner}
                showNotification={props.showNotification}
                onCipCodeGenerated={props.onCipCodeGenerated}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentWithoutCard;
