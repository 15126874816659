import {createRef, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectLanguage} from "../../../store/appInfoSlicer";
import {initializeTranslator} from "../../../helpers/language.helper";
import {downloadAndShowS3Image} from "../toolbox/EnrollmentStatusForm/helpers";
import {companies, uploadTypes} from "../../../store/enums";
import {
  formatTimestampToDateString,
  formatTimestampToDatetimeString,
  getBankName
} from "../helpers/helpers";
import InvoiceImage from "../toolbox/InvoiceImage/InvoiceImage";
import ModalDialog from "../../core/ModalDialog/ModalDialog";
import {
  getDepartamentName,
  getProvinceName,
} from "../../checkout/StudentFormAndBankAccounts/StudentInformationForm/helpers";
import {getInstallmentNumber} from "../../../helpers/string.helper";
import {
  fetchEnrollmentPayments, generatePaymentsCollection, submitEmailReminder
} from "./helper";
import ToastNotification, {
  notificationIcons,
} from "../../core/ToastNotification/ToastNotification";
import Spinner from "../../core/Spinner/Spinner";
import "./StudentDetails.scss";
import InstallmentReminderConfirmation
  from "./InstallmentReminderConfirmation/InstallmentReminderConfirmation";

/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const StudentDetails = (props) => {
  // Reference of image preview's element
  let imagePreviewRef = createRef();

  // get values from Redux state
  const language = useSelector(selectLanguage);

  // initialize translator with current language
  const t = initializeTranslator(language);

  // ---------------------------------------
  // set state values

  const [isLoading, setIsLoading] = useState(false);
  const [invoiceDialogIsVisible, setInvoiceDialogIsVisible] = useState(false);
  const [giftCourseName, setGiftCourseName] = useState("");
  const [invoicePreviewIsvisible, setInvoicePreviewIsVisible] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationIsVisible, setNotificationIsVisible] = useState(false);
  const [reminderConfirmationIsVisible, setReminderConfirmationIsVisible] = useState(false);
  const [enrollAdditionalPayments, setEnrollmentAdditionalPayments] = useState({});
  const [voucherModalOperationNumber, setVoucherModalOperationNumber] = useState('');
  const [voucherModalBank, setVoucherModalBank] = useState('');
  const [uploadType, setUploadType] = useState(uploadTypes.courseBankInvoice);

  // ---------------------------------------
  // Lifecycle hooks

  // execute when component is UPDATED
  useEffect(async () => {
    // show invoice image from S3 bucket in preview
    if (props.enrollment.bank && props.enrollment.operation_number) {
      downloadAndShowS3Image(
        imagePreviewRef.current,
        props.enrollment.bank + "-" + props.enrollment.operation_number + ".jpg",
          uploadTypes.courseBankInvoice
      );
    }

    let _payments = await fetchEnrollmentPayments(props.enrollment.enrollment_id);
    setEnrollmentAdditionalPayments(
        generatePaymentsCollection(_payments)
    )

  }, [props.enrollment]);

  // execute when component is UPDATED
  useEffect(() => {
    // get gift course name
    let _giftCourseName;
    props.courses.forEach((c) => {
      if (+c.code === +props.enrollment.gift_course) _giftCourseName = c.name;
    });

    // update component state
    setGiftCourseName(_giftCourseName);
  }, [props.courses, props.enrollment]);

  // ---------------------------------------
  // Functions

  /**
   * Submit email reminder for installment payment
   * @returns {Promise<void>}
   */
  const submitStudentPaymentReminder = async () => {
    // update state
    setReminderConfirmationIsVisible(false);
    setIsLoading(true);

    // try to submit email reminder
    let sent = await submitEmailReminder(
      props.companyName,
      props.enrollment.student_id,
      language
    );

    if (sent) {
      setNotificationTitle(t("sendPaymentReminder"));
      setNotificationMessage(t("emailNotificationSent"));
      setNotificationIsVisible(true);
    }

    // update state
    setIsLoading(false);
  };

  /**
   * Confirm whether reminder email should be sent or not
   */
  const confirmPaymentRemider = () => {
    // update component state
    setReminderConfirmationIsVisible(true);
  };

  const showVoucherModal = (bank, operationNumber, _uploadType) => {
    setVoucherModalBank(bank);
    setVoucherModalOperationNumber(operationNumber);
    setUploadType(_uploadType);
    setInvoiceDialogIsVisible(true);

  }

  // ---------------------------------------
  // return component

  return (
    <div className="student-details-component container-lg">
      {/* Loading spinner */}
      {isLoading ? <Spinner /> : null}

      {/*-----------------------------------------------------------------
        Title */}
      <div className="row section-title-wrapper">
        {/* Title area */}
        <div className="col-12 col-md-6 d-flex align-content-center">
          <img
            className="back-button"
            onClick={props.backButtonClicked}
            src="imgs/icons/arrow-left-red.svg"
            alt=""
          />
          <h1>{t("studentDetails")}</h1>
        </div>

        {/* Column empty space */}
        <div className="d-none d-md-block col-md-3" />

        {/* Edit button area */}
        <div className="col-12 col-md-3">
          {/*<RoundButton onClick={() => { }}*/}
          {/*             width={'100%'}*/}
          {/*             height={'2.5rem'}*/}
          {/*             outline={false}*/}
          {/*             content={t('editStatus')}/>*/}
        </div>
      </div>
      <div className="row">
        {/*-----------------------------------------------------------------
            Content */}
        <div className="col-12">
          <div className="content-wrapper details-panel">
            <div className="row">
              <div className="col-12 col-md-4 mb-4 pb-2">
                <span className="label">{t("name")}</span>
                <br />
                <span className="value">{props.enrollment.student}</span>
              </div>

              <div className="col-12 col-md-4 mb-4 pb-2">
                <span className="label">{t("mobile")}</span>
                <br />
                <span className="value">{props.enrollment.mobile}</span>
              </div>

              <div className="col-12 col-md-4 mb-4 pb-2">
                <span className="label">{t("email")}</span>
                <br />
                <span className="value">{props.enrollment.email}</span>
              </div>

              <div className="col-12 col-md-4 mb-4 pb-2">
                <span className="label">Documento</span>
                <br />
                <span className="value">{props.enrollment.dni}</span>
              </div>

              {props.companyName === companies.ipappg ? (
                <div className="col-12 col-md-4 mb-4 pb-2">
                  <span className="label">{t("dateOfBirth")}</span>
                  <br />
                  <span className="value">
                    {formatTimestampToDateString(props.enrollment.birthdate)}
                  </span>
                </div>
              ) : null}

              <div className="col-12 col-md-4 mb-4 pb-2">
                <span className="label">{t("courseDiploma")}</span>
                <br />
                <span className="value">{props.enrollment.course_name}</span>
              </div>

              <div className="col-12 col-md-4 mb-4 pb-2">
                <span className="label">{t("operationNumber")}</span>
                <br />
                <span className="value">
                  {props.enrollment.payed
                    ? props.enrollment.operation_number
                    : ""}
                </span>
              </div>

              <div className="col-12 col-md-4 mb-4 pb-2">
                <span className="label">{t("bank")}</span>
                <br />
                <span className="value">
                  {getBankName(props.enrollment.bank)}
                </span>
              </div>

              <div className="col-12 col-md-4 mb-4 pb-2">
                <span className="label">{t("amount")}</span>
                <br />
                <span className="value">
                  {props.enrollment.currency_code
                    ? props.enrollment.currency_code.toUpperCase()
                    : ""}
                  &nbsp;
                  {props.enrollment.amount}
                </span>
              </div>

              {props.companyName === companies.ipappg ? (
                <div className="col-12 col-md-4 mb-4 pb-2">
                  <span className="label">{t("departament")}</span>
                  <br />
                  <span className="value">
                    {getDepartamentName(props.enrollment.department)}
                  </span>
                </div>
              ) : null}

              {props.companyName === companies.ipappg ? (
                <div className="col-12 col-md-4 mb-4 pb-2">
                  <span className="label">{t("province")}</span>
                  <br />
                  <span className="value">
                    {getProvinceName(props.enrollment.province)}
                  </span>
                </div>
              ) : null}

              {props.companyName === companies.ipappg ? (
                <div className="col-12 col-md-4 mb-4 pb-2">
                  <span className="label">{t("giftCourse")}</span>
                  <br />
                  <span className="value">{giftCourseName}</span>
                </div>
              ) : null}

              <div className="col-12 col-md-4 mb-4 pb-2">
                <span className="label">{t("howDidYouHearAboutUs")}</span>
                <br />
                <span className="value">
                  {props.enrollment.how_did_you_hear_about_us
                    ? t(
                        props.enrollment.how_did_you_hear_about_us
                      ).toUpperCase()
                    : ""}
                </span>
              </div>

              <div className="col-12 col-md-4 mb-4 pb-2">
                <span className="label">{t("campaignNumber")}</span>
                <br />
                <span className="value">{props.enrollment.campaign_name}</span>
              </div>

              <div className="col-12 col-md-4 mb-4 pb-2">
                <span className="label">
                  {props.enrollment.payed
                    ? t("paymentDate")
                    : props.enrollment.installment_number >= 2
                    ? t("dueDate")
                    : t("registrationDate")}
                </span>
                <br />

                <span className="value">
                  {props.enrollment.operation_datetime
                    ? formatTimestampToDatetimeString(
                        props.enrollment.operation_datetime
                      )
                    : props.enrollment.installment_number >= 2
                    ? formatTimestampToDateString(
                        props.enrollment.installment_due_date
                      )
                    : formatTimestampToDatetimeString(
                        props.enrollment.created_at
                      )}
                </span>
              </div>

              <div className="col-12 col-md-4 mb-4 pb-2">
                <span className="label">{t("paymentMethod")}</span>
                <br />

                <span className="value">
                  {t(props.enrollment.payment_method)}
                </span>
              </div>

              <div className="col-12 col-md-4 mb-4 pb-2">
                <span className="label">{t("status")}</span>
                <br />

                <span className="value">
                  <div
                    className={
                      props.enrollment.payed
                        ? "status-icon green me-2"
                        : props.enrollment.installment_number >= 2
                        ? "status-icon blue me-2"
                        : "status-icon red me-2"
                    }
                  />

                  <span className="ms-3">
                    {props.enrollment.payed
                      ? t("payed")
                      : props.enrollment.installment_number >= 2
                      ? t("paymentInInstallments")
                      : t("pending")}
                  </span>
                </span>
              </div>

              {props.enrollment.is_installment ||
              props.enrollment.installment_number >= 2 ? (
                <div className="col-12 col-md-4 mb-4 pb-2">
                  <span className="label">{t("installment")}</span>
                  <br />
                  <span className="value">
                    {getInstallmentNumber(
                      props.enrollment.installment_number,
                      language
                    )}{" "}
                    {t("installment")}
                  </span>
                </div>
              ) : null}
            </div>
            <div className="row">
              {props.enrollment.installment_number >= 2 ? (
                <div className="col-12 col-md-4 mb-4 pb-2">
                  <span className="label"></span>
                  <br />
                  <span className="value">
                    <span
                      onClick={() => confirmPaymentRemider()}
                      className="link-button"
                    >
                      {t("sendPaymentReminder")}
                    </span>
                  </span>
                </div>
              ) : null}
            </div>

            {/* Preview is shown only when there is an image, but
               only hide element, instead of removing it, to allow
               image load correctly */}

            <div className="row">
              <div
                className="col-12 image-preview-wrapper"
                style={{ display: invoicePreviewIsvisible ? "block" : "none" }}
              >
                <img
                  ref={imagePreviewRef}
                  onLoad={() => setInvoicePreviewIsVisible(true)}
                  onError={() => setInvoicePreviewIsVisible(false)}
                  alt=""
                />
                <span
                  onClick={() => showVoucherModal(props.enrollment.bank, props.enrollment.operation_number, uploadTypes.courseBankInvoice)}
                  className="filename"
                >
                  Voucher
                </span>
              </div>
            </div>
            <div className="row pt-3">
            {Object.entries(enrollAdditionalPayments).map(([title, transactionPayments]) =>
                <div className="col-3 p-2">
                  <div className="image-preview-wrapper  p-3">
                    {title !== 'SAME_AS_COURSE'
                        ? <div className="mb-4">
                            <strong className="label">{title}</strong>
                            <button onClick={() => showVoucherModal(transactionPayments[0].bank, transactionPayments[0].operation_number, uploadTypes.certificateBankInvoice)}
                                    className="fs-6 text-decoration-none btn d-block text-primary">
                              Voucher
                            </button>
                          </div>
                        : null
                    }
                    {transactionPayments.map(p => <div className="mb-3">
                      {p.description} <br />
                      <strong>{p.currency_code.toUpperCase()} {p.amount}</strong>
                    </div>)}
                  </div>
                </div>
            )}
            </div>
          </div>
        </div>
      </div>

      {/* ---------------------------------------
        Components for notifications, dialogs, popups, etc */}

      {/* Modal dialog to show Voucher */}
      {invoiceDialogIsVisible ? (
        <ModalDialog
          close={() => setInvoiceDialogIsVisible(false)}
          content={
            <InvoiceImage
              uploadType={uploadType}
              close={() => setInvoiceDialogIsVisible(false)}
              bank={voucherModalBank}
              operationNumber={voucherModalOperationNumber}
              studentName={props.enrollment.student}
            />
          }
        />
      ) : null}

      {/* Modal dialog to confirm email reminder */}
      {reminderConfirmationIsVisible ? (
        <ModalDialog
          close={() => submitEmailReminder()}
          content={
            <InstallmentReminderConfirmation
              onCancel={() => setReminderConfirmationIsVisible(false)}
              onSubmitPaymentReminder={submitStudentPaymentReminder}
              studentName={props.enrollment.student}
            />
          }
        ></ModalDialog>
      ) : null}

      {/* Notification toast */}
      <ToastNotification
        icon={notificationIcons.check}
        title={notificationTitle}
        message={notificationMessage}
        isVisible={notificationIsVisible}
        closeCallback={() => setNotificationIsVisible(false)}
      />
    </div>
  );
};

export default StudentDetails;
