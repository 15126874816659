import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectLanguage} from "../../../store/appInfoSlicer";
import {initializeTranslator} from "../../../helpers/language.helper";
import {calculatePrice} from "../Courses/helper";
import {formatTimestampToDateString} from "../helpers/helpers";
import RoundButton from "../../core/RoundButton/RoundButton";
import SelectMenu from "../../core/SelectMenu/SelectMenu";
import {submitCourse} from "../../../helpers/courses.helper";
import ToastNotification, {
  notificationIcons,
} from "../../core/ToastNotification/ToastNotification";
import "./CourseDetails.scss";
import {companies} from "../../../store/enums";

/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CourseDetails = ({
  course,
  backButtonClicked,
  categories,
  types,
  companyName,
}) => {
  // get values from Redux state
  const language = useSelector(selectLanguage);

  // initialize translator with current language
  const t = initializeTranslator(language);

  // ---------------------------------------
  // set state values

  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [typesOptions, setTypesOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedType, setSelectedType] = useState(0);
  const [selectedMode, setSelectedMode] = useState("");
  const [installmentsCount, setInstallmentsCount] = useState(0);

  const [notificationIsVisible, setNotificationIsVisible] = useState(false);

  // ---------------------------------------
  // Lifecycle hooks

  // hook to be executed when component has been UPDATED
  useEffect(() => {
    showCourseValues();
  }, [course]);

  // hook to be executed when component has been UPDATED
  useEffect(() => {
    // Generate options for course categories

    if (categories) {
      let _categoriesOptions = [];
      categories.forEach((category) => {
        _categoriesOptions.push({
          text: category.name.replaceAll("-", " ").toUpperCase(),
          value: category.id,
        });
      });

      // update component state
      setCategoriesOptions(_categoriesOptions);
    }

    // Generate options for course types

    if (types) {
      let _typesOptions = [];
      types.forEach((type) => {
        _typesOptions.push({
          text: type.name.replaceAll("-", " ").toUpperCase(),
          value: type.id,
        });
      });

      // update component state
      setTypesOptions(_typesOptions);
    }

    showCourseValues();
  }, [categories, types]);

  // ---------------------------------------
  // functions

  const showCourseValues = () => {
    setInstallmentsCount(course.installments_count);
    setSelectedType(course.type_id);
    setSelectedCategory(course.category_id);
    setSelectedMode(course.mode);
  };

  const saveCourse = async () => {
    let saved = await submitCourse(companyName, {
      code: course.code,
      category_id: selectedCategory,
      type_id: selectedType,
      mode: selectedMode,
      installments_count: installmentsCount,
    });

    // update component state:
    // show notification and hide it after a delay

    if (saved) {
      setNotificationIsVisible(true);
      setTimeout(() => {
        setNotificationIsVisible(false);
      }, 3000);
    }
  };

  // ---------------------------------------
  // return component

  return (
    <div className="course-details-component container-lg">
      {/*-----------------------------------------------------------------
        Title */}
      <div className="row section-title-wrapper">
        {/* Title area */}
        <div className="col-12 col-md-6 d-flex align-content-center">
          <img
            className="back-button"
            onClick={backButtonClicked}
            src="imgs/icons/arrow-left-red.svg"
            alt=""
          />
          <h1>{t("courseDetails").replace("{0}", course.name)}</h1>
        </div>

        {/* Column empty space */}
        <div className="d-none d-md-block col-md-3" />

        {/* Edit button area */}
        <div className="col-12 col-md-3">
          <RoundButton
            onClick={() => saveCourse()}
            width={"100%"}
            height={"2.5rem"}
            outline={false}
            content={t("saveChanges")}
          />
        </div>
      </div>

      <div className="row">
        {/*-----------------------------------------------------------------
            Content */}
        <div className="col-12">
          <form className="content-wrapper details-panel default-form">
            <div className="row">
              <div className="col-12 col-md-4 mb-4 pb-2">
                <span className="label">{t("name")}</span>
                <br />
                <span className="value">{course.name}</span>
              </div>

              <div className="col-12 col-md-4 mb-4 pb-2">
                <span className="label">{t("salePrice")}</span>
                <br />
                <span className="value">S/ {course.price}</span>
              </div>

              <div className="col-12 col-md-4 mb-4 pb-2">
                <span className="label">{t("preSalePrice")}</span>
                <br />
                <span className="value">
                  S/{" "}
                  {calculatePrice(course.price, +course.discount_rate).toFixed(
                    2
                  )}
                </span>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-4 mb-4 pb-2">
                <span className="label">{t("courseStartDate")}</span>
                <br />
                <span className="value">
                  {formatTimestampToDateString(course.lessons_start_date)}
                </span>
              </div>

              <div className="col-12 col-md-4 mb-4 pb-2">
                <span className="label">{t("courseEndDate")}</span>
                <br />
                <span className="value">
                  {formatTimestampToDateString(course.lessons_end_date)}
                </span>
              </div>

              <div className="col-12 col-md-4 mb-4 pb-2">
                <span className="label">{t("installments")}</span>
                <br />
                <div className="input-wrapper w-50">
                  <input
                    type="text"
                    name="installments-count"
                    value={installmentsCount || 0}
                    onChange={(e) => setInstallmentsCount(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-4 mb-4 pb-2">
                <span className="label">{t("category")}</span>
                <br />
                <br />
                <div className="w-75">
                  <SelectMenu
                    description={t("selectAnOption")}
                    selectedItem={{ value: course.category_id }}
                    optionSelectedCallback={(category) => {
                      setSelectedCategory(category);
                    }}
                    options={categoriesOptions}
                  />
                </div>
              </div>

              { companyName !== companies.pragmma
                  ? <div className="col-12 col-md-4 mb-4 pb-2">
                    <span className="label">{t("type")}</span>
                    <br />
                    <br />
                    <div className="w-75">
                      <SelectMenu
                          description={t("selectAnOption")}
                          selectedItem={{ value: course.type_id }}
                          optionSelectedCallback={(type) => {
                            setSelectedType(type);
                          }}
                          options={typesOptions}
                      />
                    </div>
                  </div>
                  : null
              }

              { companyName !== companies.pragmma
                  ? <div className="col-12 col-md-4 mb-4 pb-2">
                    <span className="label">{t("mode")}</span>
                    <br />
                    <br />
                    <div className="w-75">
                      <SelectMenu
                          description={t("selectAnOption")}
                          selectedItem={{ value: course.mode }}
                          optionSelectedCallback={(mode) => {
                            setSelectedMode(mode);
                          }}
                          options={[
                            { value: "grabado", text: "Grabado" },
                            { value: "vivo", text: "En vivo" },
                            { value: "presencial", text: "Presencial" },
                          ]}
                      />
                    </div>
                  </div>

                  : null
              }

            </div>
          </form>
        </div>
      </div>

      {/* Notification toast */}

      {notificationIsVisible ? (
        <ToastNotification
          icon={notificationIcons.check}
          title={t("saved")}
          message={t("dataSentSuccessfully")}
          isVisible={notificationIsVisible}
          closeCallback={() => {
            setNotificationIsVisible(false);
          }}
        />
      ) : null}
    </div>
  );
};

export default CourseDetails;
