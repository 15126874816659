import { useEffect, useState } from "react";
import {useHistory, useParams} from "react-router-dom";
import {
  generateBrandingValues,
  setFavicon,
} from "../../helpers/branding.helper";
import { loadCssVariablesFile } from "../checkout/CheckoutPage/helpers";
import { logout } from "../../helpers/auth.helper";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../store/appInfoSlicer";
import { initializeTranslator } from "../../helpers/language.helper";
import Header from "../core/Header/Header";
import Footer from "./Footer/Footer";
import Enrollments from "./Enrollments/Enrollments";
import StudentDetails from "./StudentDetails/StudentDetails";
import Courses from "./Courses/Courses";
import {
  fetchCourses,
  sortAndFilterCourses,
} from "../../helpers/courses.helper";
import "./style.scss";
import "./sidebar-popup.scss";
import "./Backoffice.scss";
import CourseDetails from "./CourseDetails/CourseDetails";
import ConfigurationModal from "./ConfigurationModal/ConfigurationModal";
import ModalDialog from "../core/ModalDialog/ModalDialog";

const modules = Object.freeze({
  enrollments: "enrollments",
  courses: "courses",
});

/**
 * Backoffice enrollment's sections
 */
const enrollmentsSections = Object.freeze({
  list: "list",
  studentDetails: "student-details",
});

const coursesSections = Object.freeze({
  list: "list",
  courseDetails: "course-details",
});

/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Backoffice = (props) => {

  // get route parameters
  const params = useParams();

  // get language value from Redux state
  const language = useSelector(selectLanguage);

  // initialize translator with current language
  const t = initializeTranslator(language);

  // Update page title
  document.querySelector(
    "head title"
  ).innerHTML = `Backoffice - ${params.company.toUpperCase()}`;

  let { faviconIcon } = generateBrandingValues(params.company);
  setFavicon(faviconIcon);

  // get routes history instance
  let history = useHistory();

  // ---------------------------------------
  // set state values

  const [activeEnrollment, setActiveEnrollment] = useState(null);
  const [activeCourse, setActiveCourse] = useState(null);
  const [activeSection, setActiveSection] = useState("");
  const [courses, setCourses] = useState([]);
  const [coursesCategories, setCoursesCategories] = useState([]);
  const [coursesTypes, setCoursesTypes] = useState([]);
  const [coursesOptions, setCoursesOptions] = useState([]);
  const [configurationModalIsVisible, setConfigurationModalIsVisible] =
    useState(false);

  // ---------------------------------------
  // get company name from url, set logo filename,
  // and background color according company

  let { logoFilename, companyUrl } = generateBrandingValues(params.company);

  // load CSS variables
  if (params.company) loadCssVariablesFile(params.company);

  // ---------------------------------------
  // Functions

  // hook to be executed when component has been MOUNTED
  useEffect(() => {
    setActiveSection(params.section);

    // set body background color
    document.body.style.backgroundColor = "#F9F9F9";

    // fetch courses list
    fetchAndSortCourses();
  }, []);

  /**
   * Fetch courses from server, sort them alphabetically
   * and generate a new collection according SelectMenu's format
   * @returns {Promise<void>}
   */
  const fetchAndSortCourses = async () => {
    let _courses = await fetchCourses(params.company);
    if (_courses) {
      let sortedCourses = sortAndFilterCourses(_courses.companyCourses);
      let _coursesOptions = [];

      sortedCourses.forEach((c) => {
        _coursesOptions.push({
          value: c.code,
          text: `${c.name} (${c.mode})`,
        });
      });
      setCoursesOptions(_coursesOptions);
      setCourses(_courses.companyCourses);
      setCoursesCategories(_courses.categories);
      setCoursesTypes(_courses.types);
    }
  };

  // ---------------------------------------
  // Functions

  /**
   * Event handler for configutation button click
   * @returns {Promise<void>}
   */
  const onConfigClick = async () => {
    // update component state to show
    // configuration modal
    setConfigurationModalIsVisible(true);
  };

  /**
   * Event handler for logout event
   */
  const onLogoutClick = async () => {
    // request logout
    const success = await logout();

    // redirect to login page
    if (success) {
      history.push(`/${params.company}/backoffice/login`);
      // reload the page in order to reset authentication
      // variable from root component, this funcionality
      // could be replaced with Redux state
      window.location.reload();
    }
  };

  /**
   * Event handler for ActiveEnrollmentChanged event
   * @returns {Promise<void>}
   */
  const onActiveEnrollmentChanged = (enrollment) => {
    setActiveEnrollment(enrollment);
    setActiveSection(enrollmentsSections.studentDetails);
  };

  /**
   * Event handler for ActiveEnrollmentChanged event
   * @returns {Promise<void>}
   */
  const onActiveCourseChanged = (course) => {
    setActiveCourse(course);
    setActiveSection(coursesSections.courseDetails);
  };

  /**
   * Event handler for BackButtonClicked event
   * @returns {Promise<void>}
   */
  const onBackButtonClicked = (enrollment) => {
    setActiveEnrollment(null);
    setActiveSection(enrollmentsSections.list);
  };

  /**
   *
   */
  const onEnrollmentsTabClick = () => {
    history.push(`/${params.company}/backoffice/enrollments/list`);
  };

  /**
   *
   */
  const onProgramsTabClick = () => {
    history.push(`/${params.company}/backoffice/courses/list`);
  };

  // ---------------------------------------
  // return component

  return (
    <div className="backoffice-component font-main">
      {/* ---------------------------------------
        Page header */}
      <Header
        tabs={[
          { text: t("enrollments"), callback: onEnrollmentsTabClick },
          { text: t("programs"), callback: onProgramsTabClick },
        ]}
        logoFilename={logoFilename}
        configClick={onConfigClick}
        logoutClick={onLogoutClick}
        companyUrl={companyUrl}
        companyName={params.company}
      ></Header>

      {/* ---------------------------------------
        These components are rendered according
        current section */}

      {/* Students list */}
      {params.module === modules.enrollments &&
      activeSection === enrollmentsSections.list ? (
        <Enrollments
          companyName={params.company}
          courses={courses}
          coursesOptions={coursesOptions}
          activeEnrollmentChanged={onActiveEnrollmentChanged}
        />
      ) : null}

      {/* Student details */}
      {params.module === modules.enrollments &&
      activeSection === enrollmentsSections.studentDetails ? (
        <StudentDetails
          companyName={params.company}
          backButtonClicked={onBackButtonClicked}
          courses={courses}
          enrollment={activeEnrollment}
        />
      ) : null}

      {/* Student details */}
      {params.module === modules.courses &&
      activeSection === coursesSections.courseDetails ? (
        <CourseDetails
          backButtonClicked={onBackButtonClicked}
          categories={coursesCategories}
          types={coursesTypes}
          course={activeCourse}
          companyName={params.company}
        />
      ) : null}

      {/* Courses list */}
      {params.module === modules.courses &&
      activeSection === coursesSections.list ? (
        <Courses activeCourseChanged={onActiveCourseChanged} />
      ) : null}

      {/* Configuration modal */}
      {configurationModalIsVisible ? (
        <ModalDialog
          content={<ConfigurationModal companyName={params.company} />}
          close={() => {
            setConfigurationModalIsVisible(false);
          }}
        />
      ) : null}

      {/* ---------------------------------------
        Page Footer */}
      <Footer companyName={params.company} />
    </div>
  );
};

export default Backoffice;
