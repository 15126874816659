// LocalStorage keys to save values preferred by user
export const storageKeys = Object.freeze({
  language: "preferred-language",
  currency: "preferred-currency",
  country: "preferred-country",
});

export const languages = Object.freeze({
  en: "en",
  es: "es",
});

export const countries = Object.freeze({
  peru: "peru",
  mexico: "mexico",
  usa: "usa",
});

export const currencies = Object.freeze({
  pen: "pen",
  mxn: "mxn",
  usd: "usd",
});

export const paymentMethods = Object.freeze({
  niubiz: "niubiz",
  pagoefectivo: "pagoefectivo",
  yape: "yape",
  plin: "plin",
  dlocal: "dlocal",
  transfer: "bank-transfer",
});

export const companies = Object.freeze({
  enpp: "enpp",
  ipappg: "ipappg",
  pragmma: "pragmma",
});

export const banks = Object.freeze({
  bancoNacion: "banco-nacion",
  bcp: "bcp",
  interbank: "interbank",
  bbva: "bbva",
  scotiabank: "scotiabank",
  pagoLinkBCP: "pago-link-bcp",
  yapeBCP: "yape-bcp",
});

/**
 * Checkout page sections
 */
export const sections = Object.freeze({
  checkout: "checkout",
  preMatricula: "pre-matricula",
  completarMatricula: "completar-matricula",
});

export const origins = Object.freeze({
  website: "website",
  classroom: "classroom",
});

export const uploadTypes = Object.freeze({
  courseBankInvoice : 'bank-invoice-upload',
  certificateBankInvoice : 'certificate-invoice-upload'
})
