
import { createSlice } from '@reduxjs/toolkit';
import { countries, currencies, languages, storageKeys } from './enums';
import { detect, getStoredLanguage, storeLanguage } from '../helpers/language.helper';


// detect language
detect('es', ['es']);

// initialize initial state with values from localStorage,
// if no values were saved, use default values
const initialState = {
  language : getStoredLanguage() || languages.es,
  currency : localStorage.getItem(storageKeys.currency) || currencies.pen,
  country : localStorage.getItem(storageKeys.country) || countries.peru,
  userHasSetCurrency: false,
  paymentMethod : ''
};

// createSlice function allows to declarate reducers and
// generate actions automatically from reducers
const appInfoSlice = createSlice({
  name: 'appInfo',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  //
  // Redux Toolkit allows us to write "mutating" logic in reducers. It
  // doesn't actually mutate the state because it uses the Immer library,
  // which detects changes to a "draft state" and produces a brand new
  // immutable state based off those changes
  reducers: {
    changeLanguage: (state, action) => {
      state.language = action.payload;
      // save value in localStorage, to be loaded in the next initialization
      storeLanguage(state.language);
    },
    changeCurrency: (state, action) => {
      state.currency = action.payload;
      // save value in localStorage, to be loaded in the next initialization
      localStorage.setItem(storageKeys.currency, state.currency);
    },
    changeCountry: (state, action) => {
      state.country = action.payload;
      // save value in localStorage, to be loaded in the next initialization
      localStorage.setItem(storageKeys.country, state.country);
    },
    changeUserHasSetCurrency: (state, action) => {
      state.userHasSetCurrency = action.payload;
    },
    changePaymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
    }
  }
});

// Generated actions
export const { changeLanguage, changeCurrency, changeCountry, changeUserHasSetCurrency } = appInfoSlice.actions;


// Selectors
export const selectLanguage = (state) => state.appInfo.language;
export const selectCurrency = (state) => state.appInfo.currency;
export const selectCountry = (state) => state.appInfo.country;
export const selectUserHasSetCurrency = (state) => state.appInfo.userHasSetCurrency;
export const selectPaymentMethod = (state) => state.appInfo.paymentMethod;

// Reducer
export default appInfoSlice.reducer;

