import axios from "axios";
import { getEndpoint } from "../../../helpers/endpoint.helper";

/**
 * Fetch enrollments
 * @param {string} companyName
 * @param {number} page since collection should be paginated, this value
 * @param {string} criteria Search criteria
 * @param {string} courseCode course code
 * @param {string} status pending or payed
 * @param {string} months months list separated by commas (2021-03,2021-02)
 * indicates which page should be loaded
 * @returns {Promise<{}|any>}
 */
export const fetchEnrollments = async (
  companyName,
  page,
  criteria,
  courseCode,
  status,
  months
) => {
  // get base endpoint
  let endpoint;

  // default report endpoint
  endpoint = getEndpoint("enrollments-report").replace("{0}", page);

  // override endpoint
  if (status === "pending-installments") {
    endpoint = getEndpoint("pending-installments-report").replace("{0}", page);
  }

  // add query params to endpoint
  let queryParams = [];
  if (criteria) queryParams.push(`criteria=${criteria}`);
  if (courseCode) queryParams.push(`course_code=${courseCode}`);
  if (status) queryParams.push(`status=${status}`);
  if (months) queryParams.push(`months=${months}`);
  if (companyName) queryParams.push(`company_name=${companyName}`);
  endpoint += "?" + queryParams.join("&");

  try {
    let response = await axios({
      method: "get",
      url: endpoint,
      withCredentials: true,
    });

    // return paginated collection
    return response.data;
  } catch (ex) {
    return {};
  }
};

/**
 * Fetch enrollment's additional payments
 * @param companyName
 * @param months
 * @returns {Promise<array>}
 */
export const fetchAdditionalPayments = async (
    companyName, months
) => {
  // get base endpoint
  let endpoint;

  // default report endpoint, since
  // we do not need paginated results,
  // use page 0
  endpoint = getEndpoint("additional-payments-report").replace("{0}", 0);

  // add query params to endpoint
  let queryParams = [];
  if (months) queryParams.push(`months=${months}`);
  if (companyName) queryParams.push(`company_name=${companyName}`);
  endpoint += "?" + queryParams.join("&");

  try {
    let response = await axios({
      method: "get",
      url: endpoint,
      withCredentials: true,
    });

    // return collection
    return response.data;
  } catch (ex) {
    return [];
  }
}

/**
 * Submit request to delete payment log
 * @param id payment log
 * @returns {Promise<{boolean}>}
 */
export const deletePaymentLog = async (id) => {
  let endpoint = getEndpoint("payment-log").replace("{0}", id);
  return await submitDeleteRequest(endpoint);
};

/**
 * Submit request to delete enrollment (student-course)
 * @param id student-course
 * @returns {Promise<{boolean}>}
 */
export const deleteEnrollment = async (id) => {
  let endpoint = getEndpoint("enrollment").replace("{0}", id);
  return await submitDeleteRequest(endpoint);
};

/**
 * Submit request to delete payment log or enrollment
 * @param {string} endpoint API endpoint
 * @returns {Promise<{boolean}>}
 */
export const submitDeleteRequest = async (endpoint) => {
  try {
    let response = await axios({
      method: "delete",
      url: endpoint,
      withCredentials: true,
    });

    return response.data.success;
  } catch (ex) {
    return false;
  }
};
