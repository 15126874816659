import { getEndpoint } from "./endpoint.helper";
import axios from "axios";

/**
 * Fetch courses list from server
 * @param {string} companyName
 * @param {boolean} fetchLatest Ignore courses cache
 * @returns {Promise<{}>}
 */
export const fetchCourses = async (companyName, fetchLatest = false) => {
  // generate API endpoint
  let endpoint = getEndpoint("courses").replace(":company", companyName);

  // perform request to fetch course data

  try {
    let response = await axios({
      method: "get",
      url: endpoint,
    });

    return response.data;
  } catch (ex) {
    console.log(ex);
    return {};
  }
};

/**
 * Sort courses by name alphabetically, and remove
 * courses with price zero
 */
export const sortAndFilterCourses = (courses) => {

  if (!courses) return [];
  let _courses = courses.sort((a, b) => {
    let stringA = a.name.toUpperCase().trim();
    let stringB = b.name.toUpperCase().trim();
    return stringA < stringB ? -1 : stringA > stringB ? 1 : 0;
  });

  _courses = _courses.map(c => {
    c.name = `${c.name} (${c.mode})`
    return c
  })

  // use only courses with prices higher than zero
  let alreadyAdded = [];
  _courses = _courses.filter(c => {
    let courseCode = c.code;

    // Add course only if its price is higher
    // than zero, and it is not already added
    // to _courses collection

    if (+c.price > 0 && !alreadyAdded.includes(courseCode) && c.is_featured) {
      alreadyAdded.push(courseCode);
      return true;
    } else {
      return false;
    }
  });

  // Generate text for SELECT input, when course type is
  // defined the text includes type and course name,
  // or use course name otherwise

  _courses = _courses.map((c) => {
    if (c.type) {
      let type = c.type.charAt(0).toUpperCase() + c.type.slice(1);
      c.text = `${type} - ${c.name}`;
      c.value = c.code;
    } else {
      c.text = c.name;
      c.value = c.code;
    }
    return c;
  });

  return _courses;
}

/**
 * Filter gift courses for selected course
 * @returns {*[]}
 */
export const filterGiftCourses = (courseCode, courses, giftCourses) => {
  // find course item

  let course = courses.find((c) => +c.code === +courseCode);

  if (course) {
    // get codes of gift courses
    let coursesIds = [];
    giftCourses.forEach(giftCourse => {
      if (+giftCourse.giftlist_id === +course.giftlist_id) {
        coursesIds.push(+giftCourse.course_id);
      }
    });

    // filter courses
    let added = []; // ids already added
    const found = courses.filter((_course) => {
      // check if course should be added to gift courses
      if (coursesIds.includes(+_course.id)) {
        // add gift course only when it has not been added previously
        let alreadyIncluded = added.includes(+_course.id);
        if (alreadyIncluded) {
          return false;
        } else {
          // add id to array, to avoid adding it again
          added.push(+_course.id);
          return true;
        }
      } else {

        return false;
      }
    });

    return found;
  } else {
    return [];
  }
};

/**
 * Calculate course price including certificates prices,
 * and its price with installments
 */
export const calculateCoursePrices = (
  course,
  withInstallments,
  certificatesIds
) => {
  let certificatesSum = sumCertificatesPrices(course, certificatesIds);
  let _coursePrice = withInstallments ? course.installmentPrice : course.price;

  return {
    coursePrice: +_coursePrice,
    totalPrice: +(certificatesSum + _coursePrice),
  };
};

/**
 * Calculate certificates total price
 */
export const sumCertificatesPrices = (course, certificatesIds) => {
  let certificatesPriceSum = 0;
  course.certificates.forEach((certificate) => {
    let id = +certificate.id;
    if (certificatesIds.includes(id)) {
      certificatesPriceSum += +certificate.price;
    }
  });

  return certificatesPriceSum;
};

/**
 * Submit course values to backend to be updated
 * @param companyName
 * @param values
 * @returns {Promise<*>}
 */
export const submitCourse = async (companyName, values) => {
  let success = true;
  let endpoint = getEndpoint("course-update").replace(":company", companyName);
  try {
    // perform request

    let response = await axios({
      url: endpoint,
      data: values,
      method: "put",
      withCredentials: true,
    });

    success = response.data.success;
  } catch (ex) {
    console.log(ex);
    success = false;
  }

  return success;
};
