import { initializeTranslator } from "../../../../helpers/language.helper";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../../store/appInfoSlicer";

/**
 * Component
 * @returns {JSX.Element}
 * @constructor
 */
const YapeInformation = () => {
  // get language value from Redux state
  const language = useSelector(selectLanguage);

  // initialize translator with current language
  const t = initializeTranslator(language);

  // return component

  return (
    <div>
      <p className="mt-4 d-block description">{t("yapeDescription")}</p>
      <p>
        <img src="imgs/logos/whatsapp.svg" className="me-3" alt="" />
        <a
          href={`https://wa.me/51946554339?text=${t("whatsappMessage")}`}
          target="_blank"
        >
          (+51) 946 554 339
        </a>{" "}
        ( {t("salesManager")} )
      </p>

      <div className="qrcode-wrapper mt-5">
        <img
          src="imgs/qrcode_yape_enpp.png"
          style={{ width: "10.625rem" }}
          alt=""
        />
      </div>
    </div>
  );
};

export default YapeInformation;
