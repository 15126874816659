import { useEffect, useState } from "react";
import "./ToastNotification.scss";

export const notificationIcons = Object.freeze({
  check: "check-2",
  error: "error",
});

/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ToastNotification = (props) => {
  // CSS classes
  const hiddenClasses = "row panel justify-content-center";
  const visibleClasses = "row panel is-visible justify-content-center";

  // ---------------------------------------
  // set state values
  const [timeoutDelayIsPending, setTimeoutDelayIsPending] = useState(false);

  // ---------------------------------------
  // lifecycle hooks

  // execute when component is UPDATED
  useEffect(() => {
    if (!timeoutDelayIsPending && props.isVisible) {
      // calls close callback after a delay
      setTimeout(() => {
        props.closeCallback();
        // update state
        setTimeoutDelayIsPending(false);
      }, 5000);

      // update state
      setTimeoutDelayIsPending(true);
    }
  }, [props.isVisible]);

  // ---------------------------------------
  // return component

  return (
    <div className="toast-notification-component">
      <div className="container container-md">
        <div className={props.isVisible ? visibleClasses : hiddenClasses}>
          <div className="col-2 d-flex justify-content-center">
            <img
              className="icon"
              src={`imgs/icons/${
                props.icon ? props.icon : notificationIcons.error
              }.svg`}
              alt=""
            />
          </div>
          <div className="col-10 position-relative pt-3">
            {/* Notification title */}
            <h3>{props.title}</h3>

            {/* Notification message */}
            <p className="message">{props.message}</p>

            {/* Close button */}
            <div onClick={props.closeCallback} className="close button">
              <img src="imgs/icons/close.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToastNotification;
